export const data = [
  
    {
        name:"Data Entry"
      },
      {
        name:"Voice Over Artist"
      },
      {
        name:"Invitation Designing"
      },
      {
        name:"HR Consultant / Recruite / Manager"
      },
      {
        name:"SEO"
      },
      {
        name:"SEO Manager"
      },
      {
        name:"Lead Generation"
      },
      {
        name:"Excel Expert"
      },
      {
        name:"Power point"
      },
      {
        name:"Resume / CV"
      },
      {
        name:"Translation "
      },
      {
        name:"Digital Marketing"
      },
      {
        name:"App Developer"
      },
      {
        name:"Content writing"
      },
      {
        name:"Youtube Thumbnail "
      },
      {
        name:"Pdf"
      },
      {
        name:"Word"
      } ,
      {
        name:"Social Media Handling"
      } ,
      {
        name:"Social Media Management"
      },
      {
        name:"Copywriter"
      },
      {
        name:"3D / 2D Model"
      },
      {
        name:"Photography "
      },
      {
        name:"Event Management"
      },
      {
        name:"Party Planner"
      },
      {
        name:"Sketch Artist"
      },
      {
        name:"Food Consultant"
      },
      {
        name:"Banner "
      },
      {
        name:"Facebook Marketing"
      },
      {
        name:"Shopify"
      },
      {
        name:"IOS Development"
      },
      {
        name:"Virtual Assistant"
      },
      {
        name:"Report Writing"
      },
      {
        name:"Telecaller"
      },
      {
        name:"Backoffice work"
      },
      {
        name:"Legal Consultant"
      },
      {
        name:"Income tax Filer"
      },
      {
        name:"Food Catering"
      },
      {
        name:"Auditor"
      },
      {
        name:"Book Keeping"
      },
      {
        name:"Chartered Accountant"
      },
      {
        name:"Company Secretary"
      },
      {
        name:"Data Analyst"
      },
      {
        name:"C++ Language"
      },
      {
        name:"BLock chain"
      },
      {
        name:"SQL"
      },
      {
        name:"PHP"
      },
      {
        name:".NET"
      },
      {
        name:"Business Analysis"
      },
      {
        name:"Node.JS"
      },
      {
        name:"MIcrosoft"
      },
      {
        name:"MIcrosoft Office"
      },
      {
        name:"User Tester"
      },
      {
        name:"Creative design"
      },
      {
        name:"Creative writing"
      },
      {
        name:"Google my Business Manager"
      },
      {
        name:"Software Architect"
      },
      {
        name:"Research"
      },
      {
        name:"Courier Coaching"
      },
      {
        name:"Tuition"
      },
      {
        name:"Audio Transcription"
      },
      {
        name:"Alogorith"
      },
      {
        name:"Translator"
      },
      {
        name:"Photoshop"
      },
      {
        name:"Internet Marketing"
      },
      {
        name:"AI Developer"
      },
      {
        name:"linux"
      },
      {
        name:"Apache"
      },
      {
        name:"Apache Maven"
      },
      {
        name:"Bootstrap"
      },

      {
        name:"Fashion Designer"
      },
      {
        name:"editor"
      },
      {
        name:"Audio & Video Editing"
      },
      {
        name:"Speciality CHef"
      },
      {
        name:"Generalist"
      } ,
      {
        name:"Customer Support"
      },
      {
        name:"Portrait"
      },
      {
        name:"Cover Art"
      },
      {
        name:"Proposal Maker"
      },
      {
        name:"Architect"
      },
      {
        name:"Floor Plan"
      },
      {
        name:"financial advisor"
      },
      {
        name:"Space planning"
      },
      {
        name:"Hotel Design"
      },
      {
        name:"Fitness Coach"
      },
      {
        name:"Resturant design"
      },
      {
        name:"Robotics"
      },
      {
        name:"human COmputer interactor"
      },
      {
        name:"receptionist"
      },
      {
        name:"Public Relation (PR)"
      },
      {
        name:"Campaign"
      },
      {
        name:"System Engineer"
      },
      {
        name:"Meditaion "
      },
      {
        name:"Immigration & International"
      },
      {
        name:"Health & Medical "
      },
      {
        name:"Medical COnsultant"
      },
      {
        name:"Broker"
      },
      {
        name:"Linguistics"
      },
      {
        name:"Therapist"
      },
      {
        name:"psychologist"
      },
      {
        name:"Counsellor"
      },
      {
        name:"Problem - Solving Skills"
      },
      {
        name:"Trainer"
      },
      {
        name:"Wordpress"
      },
      {
        name:"Narration"
      },
      {
        name:"Astrologer"
      },
      {
        name:"singer"
      },
      {
        name:"Lyricists"
      },
      {
        name:"composer"
      },
      {
        name:"Subtitle Writer / Speaker"
      },
      {
        name:"paraphrasing"
      },
      {
        name:"jewellery designer"
      },
      {
        name:"Adobe"
      },
      {
        name:"Coral Draw"
      },
      {
        name:"book Writer"
      },
      {
        name:"Movers & Packers"
      },
      {
        name:"Ui / UX "
      }, 
      {
        name:"Makeup Artist"
      },
      {
        name:"dermatologist"
      },
      {
        name:"Skin Consultant"
      },
];
